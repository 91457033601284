exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-businesses-enterprises-jsx": () => import("./../../../src/pages/businesses/enterprises.jsx" /* webpackChunkName: "component---src-pages-businesses-enterprises-jsx" */),
  "component---src-pages-businesses-gaming-jsx": () => import("./../../../src/pages/businesses/gaming.jsx" /* webpackChunkName: "component---src-pages-businesses-gaming-jsx" */),
  "component---src-pages-businesses-index-jsx": () => import("./../../../src/pages/businesses/index.jsx" /* webpackChunkName: "component---src-pages-businesses-index-jsx" */),
  "component---src-pages-businesses-startups-jsx": () => import("./../../../src/pages/businesses/startups.jsx" /* webpackChunkName: "component---src-pages-businesses-startups-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-jsx": () => import("./../../../src/pages/industries.jsx" /* webpackChunkName: "component---src-pages-industries-jsx" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-one-post-jsx": () => import("./../../../src/templates/one-post.jsx" /* webpackChunkName: "component---src-templates-one-post-jsx" */),
  "component---src-templates-portfolio-item-jsx": () => import("./../../../src/templates/portfolio-item.jsx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx" */),
  "component---src-templates-service-item-jsx": () => import("./../../../src/templates/service-item.jsx" /* webpackChunkName: "component---src-templates-service-item-jsx" */)
}

